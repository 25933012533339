<template>
  <div>
    <strong>Usuario: {{ props.row.usuario }}</strong><br>
    <b-tag v-if="props.row['log_id_cambio']">ID CIAM: {{ props.row.log_id_cambio }}</b-tag>
    <b-tag v-else-if="props.row['log_id_creacion']">ID CIAM: {{ props.row.log_id_creacion }}</b-tag>
    <br>
    <small>Fecha: {{ formatDate(props.row.fechaHora) }}</small><br>
    <small>Tipo de documento: {{ typeDocFormatting(props.row.usuario) }}</small><br>
    <small>Documento de identidad: {{ numDocFormatting(props.row.usuario) }}</small><br>
    <small>Pais / APP: {{ props.row.pais }}</small><br>
    <small>Código de respuesta: {{ props.row.resultado }}</small><br>
    <small>Mensaje: {{ messageFormatting(props.row.mensaje) }}</small><br>
    <b-tag :type="getTagState(props.row.resultado)">
      Estado: {{ getStatusText(props.row.resultado) }}
    </b-tag>
  </div>
</template>

<script>
import {
  dateFormatting,
  getStatusText,
  getTagState, messageFormatting,
  numDocFormatted,
  typeDocFormatting
} from "../../../assets/config/utilities";

export default {
  name: "FirstCardDetail",
  props: [
    'props'
  ],
  methods: {
    formatDate(date) {
      return dateFormatting(date);
    },
    getTagState(status) {
      return getTagState(status);
    },
    getStatusText(status) {
      return getStatusText(status);
    },
    typeDocFormatting(user) {
      return typeDocFormatting(user);
    },
    numDocFormatting(user) {
      return numDocFormatted(user);
    },
    messageFormatting(message) {
      return messageFormatting(message);
    }
  }
}
</script>

<style scoped>

</style>